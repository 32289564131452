import * as React from "react"
import {progressbar} from './playbar.module.scss'
const PlayBar = () => {
    const [scroll, setScroll] = React.useState(0);

    React.useEffect(() => {
    
        let progressBarHandler = () => {
            
            const totalScroll = document.documentElement.scrollTop;
            const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scroll = `${totalScroll / windowHeight}`;
    
            setScroll(scroll);
            console.log(scroll*100);
        }
    
        window.addEventListener("scroll", progressBarHandler);
  
        return () => window.removeEventListener("scroll", progressBarHandler);
    });

    return (
        <div  style={{"--percentage":`${scroll*100}%`}} className={progressbar} data-percentage={scroll*100} ></div>
    )
}

export default PlayBar