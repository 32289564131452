import { graphql } from "gatsby"
import * as React from "react"
import Header from "../components/header"
import Sidebar from "../components/sidebar"
import { MDXRenderer } from 'gatsby-plugin-mdx'

import {
  main,
  main__container,
  credits
} from "./{mdx.slug}.module.scss"
import { AudioPlayerProvider } from "react-use-audio-player"
import AudioPlayer from "../components/audioPlayer"
import Seo from "../components/seo"

const Story = ({ data }) => {
  let stories = data.allMdx.nodes
  return (
    <div>
      {/* <title>Stories | {data.mdx.frontmatter.subtitle}</title> */}
      <Seo title={data.mdx.frontmatter.subtitle}/>
      <Header>
      <AudioPlayerProvider>
            <AudioPlayer file={data.mdx.frontmatter.audio} title={data.mdx.frontmatter.subtitle}/>
      </AudioPlayerProvider>
      </Header>
      
      <div className={main}>
        <Sidebar type="header-mod" stories={stories}/>
        <div className={main__container}>
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
            <p className={credits}>{data.mdx.frontmatter.subtitle} è interpretato da {data.mdx.frontmatter.audioCredits}</p>
        </div>
      </div>
    </div>
  )
 
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        subtitle
        audio
        audioCredits
      }
      body
    }
    allIndexImagesJson {
        nodes {
          title
          text
        }
      }
    allMdx(sort: {order: ASC, fields: [frontmatter___title]}) {
      nodes {
        slug
        frontmatter {
          title
          subtitle
        }
      }
    }
  }
`
export default Story