import * as React from 'react'

import { useAudioPlayer, useAudioPosition } from "react-use-audio-player"
import { subheader, player, player__info, player__infoControls, player__infoControlsBlock, player__infoTitle, button } from './audioPlayer.module.scss'
import PlayBars from './playbar'

const AudioPlayer = ({ file, title }) => {
    const { togglePlayPause, ready, loading, playing } = useAudioPlayer({
        src: file,
        format: "mp3",
        autoplay: false,
        onend: () => console.log("sound has ended!")
    })
    const { position, duration} = useAudioPosition({ highRefreshRate: true })

    // const onTogglePlayPause = React.useCallback(() => {
    //     togglePlayPause()
    // }, [togglePlayPause])

    if (!ready && !loading)
        return (
            <div>No audio to play</div>
        )
    if (loading)
        return (
            <div className={subheader}>
            <div className={player}>
                <div className={player__info}> 
                    <div className={player__infoTitle}>{title}</div>
                    <div className={player__infoControls}>
                        <div className={player__infoControlsBlock}>Audio: --</div>
                        <div className={player__infoControlsBlock}>
                            <span>--:--</span> / <span>--:--</span>
                        </div>
                    </div>
                </div>
            </div>
            <PlayBars></PlayBars>
        </div>
        )

    return (
        <div className={subheader}>
            <div className={player}>
                <div className={player__info}>
                    <div className={player__infoTitle}>{title}</div>
                    <div className={player__infoControls}>
                        <div className={player__infoControlsBlock}><span>Audio:</span> <button className={button} onClick={togglePlayPause}>{playing ? "off" : "on"}</button></div>
                        <div className={player__infoControlsBlock}>
                            <span>{new Date(position * 1000).toISOString().substr(14, 5)}</span> / <span>{new Date(duration * 1000).toISOString().substr(14, 5)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <PlayBars></PlayBars>
        </div>
    )
}

export default AudioPlayer;